import Header from "../components/Header";
import Main from "../components/Main";

// import Navigation from "../components/Navigation/Navigation";



const HomePage = () => {
  return (
    <>
      <Header/>
      <Main/>
     
    </>
  );
};

export default HomePage;